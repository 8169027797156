import styled from 'styled-components';
import * as React from 'react';

type LinkData = {
  slug: string;
  label: string;
};

type Props = {
  links: LinkData[];
};

const LinkList = styled.ol`
  font-family: Quicksand, serif;
  font-style: normal;
  font-weight: normal;

  font-size: 1.5rem;
  padding-left: 1.3rem;
  margin-top: -.9rem;
  margin-bottom: .3rem;
  li {
    margin-bottom: 1.1rem;
    a {
      color: ${({ theme }) => theme.blogColors.fontPrimary2};
    }
  }

  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    li {
      margin-bottom: .5rem;
    }
  }

  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    font-size: 2rem;

    padding-left: 1.8rem;
    margin-top: -.3rem;

    li {
      margin-bottom: 1rem;
    }
  }
`;

export const LocalLinksSection: React.FC<Props> = ({ links }) => (
  <nav>
    <LinkList>
      {links.map(item => (
        <li key={item.slug}>
          <a href={`#${item.slug}`}>
            {item.label}
          </a>
        </li>
      ))}
    </LinkList>
  </nav>
);
