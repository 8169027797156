import styled from 'styled-components';
import * as React from 'react';

type TypesData = {
  slug?: string;
  label: string;
  count: number;
};

type Props = {
  types: TypesData[];
};

const PostTypesList = styled.ul`
  font-family: Quicksand, serif;
  font-style: normal;
  font-weight: bold;

  list-style-type: none;

  font-size: 1.2rem;

  text-transform: uppercase;

  color: ${({ theme }) => theme.blogColors.fontPrimary4};

  padding-left: 0;
  margin-top: -0.2rem;
  margin-bottom: .3rem;

  li {
    display: flex;
    margin-bottom: 1.5rem;
    a {
      color: black;
    }
  }

  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    li {
      margin-bottom: .8rem;
    }
  }

  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    font-size: 1.8rem;
    margin-top: .2rem;

    li {
      margin-bottom: 1.2rem;
    }
  }
`;

const Count = styled.div`

`;

const Label = styled.div`
  flex: 1;
`;

export const PostTypesSection: React.FC<Props> = ({ types }) => (
  <nav>
    <PostTypesList>
      {types.map(item => (
        <li key={item.label}>
          <Label>
            {item.slug
              ? (
                <a href={`#${item.slug}`}>
                  {item.label}
                </a>
              )
              : item.label}
          </Label>
          <Count>
            ({item.count})
          </Count>
        </li>
      ))}
    </PostTypesList>
  </nav>
);
