import styled from 'styled-components';
import * as React from 'react';

type LinkData = {
  label: string;
};

type Props = {
  tags: LinkData[];
};

const Container = styled.ul`
  font-size: 1.2rem;
  font-family: Quicksand, serif;
  font-style: normal;
  font-weight: normal;
  padding: 0;
  margin: 0 -.5rem 0px 0;
  cursor: default;

  li {
    display:inline-block;

    margin-right: .5rem;
    margin-bottom: .8rem;

    padding: .5rem 1.5rem;
    background-color: ${({ theme }) => theme.blogColors.primary2};
    color: ${({ theme }) => theme.blogColors.fontPrimary2};
    border-radius: 1.2rem;

    &:hover {
      background-color: ${({ theme }) => theme.blogColors.primary4};
      color: ${({ theme }) => theme.blogColors.fontPrimary3};
    }
  }

  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    font-size: 1.8rem;
    margin-top: 0.7rem;

    li {
      margin-right: .5rem;
      margin-bottom: 1rem;

      padding: .8rem 2.5rem;
      border-radius: 15rem;
    }
  }

`;

export const TopicsSection: React.FC<Props> = ({ tags }) => (
  <Container>
    {tags.map(item => (
      <li key={item.label}>
        <span>{item.label}</span>
      </li>
    ))}
  </Container>
);
